import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Logo } from '@shared/components';
import {
  changeFilesSearchTerm,
  clearSelectedFilesIds,
  removeFromFileBreadcrumbs,
  selectActiveFileId,
  selectFilesBreadcrumbs,
  selectFilesRequestParams
} from '@state/files/filesSlice';
import { AccountMenu } from '@common';
import SearchField from '@common2/User/SearchField/SearchField';
import { useHistory } from 'react-router-dom';
import { UploadProgress } from './UploadProgress';

interface AccountHeaderProps {
  isAdminOrStaff?: boolean;
}

export const AccountHeader = ({ isAdminOrStaff = false }: AccountHeaderProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const activeFileId = useAppSelector(selectActiveFileId);
  const history = useHistory();
  const { searchTerm: searchInput } = useAppSelector(selectFilesRequestParams);
  const breadcrumbs = useAppSelector(selectFilesBreadcrumbs);
  const onSearch = (newSearchTerm: string) => {
    dispatch(changeFilesSearchTerm(newSearchTerm));
  };

  const handleBack = () => {
    if (breadcrumbs[0]?.name?.toLowerCase()?.includes('albums')) {
      history.push(`/home/files/albums`);
    } else {
      history.push(`/home/files/all`);
    }
    dispatch(removeFromFileBreadcrumbs(activeFileId));
    dispatch(clearSelectedFilesIds());
  };

  const showBack = window.location.pathname.includes('/preview');

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      sx={{ width: 1 }}
    >
      {isAdminOrStaff && <Logo sx={{ height: 45 }} />}
      {!isAdminOrStaff && !showBack && (
        <Stack
          direction="row"
          justifyContent="start"
          spacing={2}
          sx={{ width: 1, maxWidth: '700px' }}
        >
          <SearchField value={searchInput} onChange={onSearch} placeholder={t('home.searchBox')} />
        </Stack>
      )}
      {!isAdminOrStaff && showBack && (
        <Stack
          direction="row"
          justifyContent="start"
          gap="11px"
          onClick={handleBack}
          alignItems="center"
          sx={{ color: 'white', fontWeight: '700', fontSize: '20px', cursor: 'pointer' }}
        >
          <ArrowBackIcon />
          {t('backButton')}
        </Stack>
      )}
      <div style={{ display: 'flex', gap: '20px' }}>
        <UploadProgress />
        <AccountMenu />
      </div>
    </Stack>
  );
};
