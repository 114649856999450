import React, { lazy, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import actualTheme from '@shared/theme';
import { useDispatch } from 'react-redux';
import { setIsMobile } from '@state/files/filesSlice';
import { useGetCurrentUserData } from '@hooks/useGetCurrentUserData';

const UserRouterDesktop = lazy(() =>
  import(/* webpackChunkName: "UserRouterDesktop" */ './user_router/userRouterDesktop')
);

const UserRouterMobile = lazy(() =>
  import(/* webpackChunkName: "UserRouterMobile" */ './user_router/userRouterMobile')
);

const userRouter = () => {
  const isMobile = useMediaQuery(actualTheme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  useGetCurrentUserData();
  const isFirstRender = useRef<boolean>(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      // dispatch(changeFilesViewType(GRID));
    }
    dispatch(setIsMobile(isMobile));
  }, [isMobile]);

  return isMobile ? <UserRouterMobile /> : <UserRouterDesktop />;
};

export default userRouter;
