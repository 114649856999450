import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CircularProgress, styled } from '@mui/material';
import SignUp from '@pages/sign_up';
import SignIn from '@pages/sign_in';
import ChangePassword from '@pages/change_password';
import RecoveringPassword from '@pages/recovering-password';
import SetPassword from '@pages/set_password';
import Home from '@pages/main';
import AdminPage from '@pages/admin';
import StaffPage from '@pages/staff';
import SuccessPayment from '@pages/success_payment';
import { PAGES } from '@helpers';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const SuspenseFallback = styled(CircularProgress)({
  position: 'absolute',
  width: 60,
  height: 60,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto'
});

const Routing = () => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to={PAGES.Login} />
          </Route>
          <Route exact path="/payments/stripe/success">
            <SuccessPayment />
          </Route>
          <PublicRoute path={PAGES.SignUp}>
            <SignUp />
          </PublicRoute>
          <Route exact path="/change-password">
            <ChangePassword />
          </Route>
          <PublicRoute path="/set-password">
            <SetPassword />
          </PublicRoute>
          <PublicRoute path="/recovering-password">
            <RecoveringPassword />
          </PublicRoute>
          <PublicRoute path={PAGES.Login}>
            <SignIn />
          </PublicRoute>
          <PrivateRoute path={PAGES.Home}>
            <Home />
          </PrivateRoute>
          <PrivateRoute path={PAGES.Admin}>
            <AdminPage />
          </PrivateRoute>
          <PrivateRoute path={PAGES.Staff}>
            <StaffPage />
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default Routing;
