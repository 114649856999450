import React, { useContext, useEffect } from 'react';
import { PlatformConfigByEnv } from '@aws';
import { AuthContext } from '@contexts';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import Popover from '@mui/material/Popover';
import { Box, Stack } from '@mui/system';
import { selectActiveUserData, setUploadProgress } from '@state/users/userSlice';
import Tus from '@uppy/tus';
import Uppy from '@uppy/core';
import ImageEditor from '@uppy/image-editor';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/image-editor/dist/style.min.css';
// import '@uppy/progress-bar/dist/style.css';
// import '@uppy/status-bar/dist/style.min.css';
import Facebook from '@uppy/facebook';
import Instagram from '@uppy/instagram';

import { LinearProgress, Typography, linearProgressClasses, styled } from '@mui/material';
import { fetchUserInfoByUserId } from '@state/users/thunks';
import { useTranslation } from 'react-i18next';

import { UserProfileData } from '@state/types';
import { getSizeFormat } from '@utils/getSizeFormat';
import { apiSlice, usePostUploadStatusMutation } from '@state/apiSlice';
import { selectActiveDirectory } from '@state/files/filesSlice';
import { setGlobalSuccessToastMessage } from '@common';
import client from '../../../apollo/client';

const TUS_ENDPOINT = PlatformConfigByEnv.tus_endpoint;
if (!TUS_ENDPOINT) {
  throw new Error('REACT_APP_TUS_ENDPOINT is required');
}

export interface UppyFilesDialogProps {
  anchorEl: Element | null;
  onClose: () => void;
}
const StorageUsedCategory = styled(Typography)({
  fontWeight: 500,
  marginTop: 8
});
const BorderLinearProgress = styled(LinearProgress)({
  height: 1,
  borderRadius: 10,
  marginTop: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E2EBFA'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#83E2DA'
  }
});

function calculateTotalSize(files: any, currentSize: number, operation: 'add' | 'remove') {
  return files.reduce(
    (acc, file) => (operation === 'add' ? acc + file.size : acc - file.size),
    currentSize
  );
}

function getFullId(currentFile: any) {
  const fid = `${currentFile.id}/user=${currentFile.meta.userId}/root=${
    currentFile.meta.rootDirectoryId || '.'
  }`;
  currentFile.id = fid;
  return currentFile;
}

const uppy = new Uppy({
  onBeforeFileAdded: getFullId
})
  .use(Tus, {
    endpoint: TUS_ENDPOINT,
    removeFingerprintOnSuccess: true,
    parallelUploads: 1
  })
  // .use(Dashboard, {
  //   // target: '#dashboard',
  //   proudlyDisplayPoweredByUppy: false,
  //   showProgressDetails: true,
  //   fileManagerSelectionType: 'both',
  //   metaFields: [{ id: 'description', name: 'Description', placeholder: 'description' }]
  // })
  .use(ImageEditor, {
    actions: {
      revert: true,
      rotate: true,
      granularRotate: true,
      flip: true,
      zoomIn: true,
      zoomOut: true,
      cropSquare: true,
      cropWidescreen: true,
      cropWidescreenVertical: true
    }
  })
  .use(Instagram, { companionUrl: PlatformConfigByEnv.companion_endpoint })
  .use(Facebook, { companionUrl: PlatformConfigByEnv.companion_endpoint });

export function UppyFilesDialog({ anchorEl, onClose }: UppyFilesDialogProps) {
  /** *
   * Uppy Doc is https://uppy.io/docs/uppy/
   * TODO: Rerender less
   * TODO: Add error handling
   *
   *
   * */
  const { t } = useTranslation();
  const { activeUserId } = useAppSelector(selectActiveUserData);
  const { user } = useContext(AuthContext);
  const currentUserId = activeUserId || user?.attributes.sub || '';
  const dispatch = useAppDispatch();
  const { id: activeDirectoryId } = useAppSelector(selectActiveDirectory);
  const [bytesSelected, setBytesSelected] = React.useState<number>(0);
  const [userProfile, setUserProfile] = React.useState<UserProfileData | undefined>();
  const [postUploadStatus] = usePostUploadStatusMutation();

  function onFilesAdded(files: any) {
    setBytesSelected((prev) => calculateTotalSize(files, prev, 'add'));
  }

  function onFileRemoved(file: any) {
    setBytesSelected((prev) => calculateTotalSize([file], prev, 'remove'));
  }

  const invalidateData = () => {
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'queryUserFile'
    });
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'aggregateUserFile'
    });
  };

  function onComplete(result: any) {
    postUploadStatus(result);
    // TODO Delete with new logic written via WS.
    dispatch(setUploadProgress({ percent: 0, status: '' }));
    setGlobalSuccessToastMessage('Files successfully uploaded!');
    setTimeout(invalidateData, 3000); // The data doesn't have time to update on the server.
  }

  useEffect(() => {
    async function fetchUserProfile() {
      const response = await dispatch(fetchUserInfoByUserId(currentUserId as string));
      const up: UserProfileData = response.payload as UserProfileData;
      setUserProfile(up);
      setBytesSelected(up.family_storage_used || 0);
    }
    fetchUserProfile();
  }, [currentUserId]);

  useEffect(() => {
    uppy.on('files-added', onFilesAdded);
    uppy.on('complete', onComplete);
    uppy.on('file-removed', onFileRemoved);
    uppy.on('progress', (progress) => {
      if (progress !== 0) {
        dispatch(setUploadProgress({ percent: progress, status: 'Uploading:' }));
      } else {
        dispatch(setUploadProgress({ percent: 0, status: '' }));
      }
    });
    uppy.on('pause-all', () => {
      dispatch(setUploadProgress({ status: 'Pause' }));
    });
    uppy.on('cancel-all', () => {
      dispatch(setUploadProgress({ percent: 0, status: '' }));
    });

    return () => {
      uppy.off('files-added', null);
      uppy.off('complete', null);
      uppy.off('file-removed', null);
      uppy.off('progress', null);
      uppy.off('pause-all', null);
      uppy.off('cancel-all', null);
    };
  }, []);

  useEffect(() => {
    uppy.setMeta({ userId: currentUserId });
    uppy.setMeta({ rootDirectoryId: activeDirectoryId });
  }, [currentUserId, activeDirectoryId]);

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      >
        <Stack direction="row">
          <div id="dashboard" />
          <Dashboard
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            showProgressDetails
            fileManagerSelectionType="both"
            metaFields={[{ id: 'description', name: 'Description', placeholder: 'description' }]}
          />
        </Stack>
        <Stack
          sx={{
            backgroundColor: '#FFFFFF',
            width: '100%',
            borderRadius: '8px',
            p: 3,
            height: 'fit-content'
          }}
        >
          <StorageUsedCategory>{t('home.accountInfo.storageUsed')}</StorageUsedCategory>
          <Box sx={{ height: 8 }}>
            <BorderLinearProgress
              variant="determinate"
              value={userProfile ? (bytesSelected / userProfile.subscription_size) * 100 : 0}
              sx={{ height: 1 }}
            />
          </Box>
          <Typography sx={{ mt: 3, opacity: 0.8 }}>
            {t('home.accountInfo.storage-used-size', {
              usedSize: getSizeFormat(bytesSelected),
              totalSize: getSizeFormat(userProfile?.subscription_size || 0)
            })}
          </Typography>
        </Stack>
      </Popover>
    </>
  );
}
