import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';

export const MainContainedBtn = styled(MuiButton)(({ theme: { // @ts-ignore
    palette: { containedButton }, breakpoints }, styleVar = 'primary' }) => ({
  color: containedButton[styleVar].color,
  width: '100%',
  backgroundColor: containedButton[styleVar].bgDefault,
  borderColor: containedButton[styleVar].bgDefault,
  boxShadow: 'none',
  fontSize: '18px',
  fontWeight: '800',

  [breakpoints.down('md')]: {
    fontSize: '16px',
    fontWeight: '700'
  },

  '&:hover': {
    boxShadow: 'none',
    color: containedButton[styleVar].color,
    backgroundColor: containedButton[styleVar].bgHover,
    borderColor: containedButton[styleVar].bgHover
  },
  '&:active': {
    boxShadow: 'none',
    color: containedButton[styleVar].color,
    backgroundColor: containedButton[styleVar].bgActive,
    borderColor: containedButton[styleVar].bgActive
  },
  '&:disabled': {
    boxShadow: 'none',
    color: containedButton[styleVar].color,
    backgroundColor: containedButton[styleVar].bgDisabled,
    borderColor: containedButton[styleVar].bgDisabled
  }
}));

export const MainTextBtn = styled(MuiButton)(({ theme: { // @ts-ignore
    palette: { containedButton }, breakpoints }, styleVar = 'primary' }) => ({
  color: containedButton[styleVar].color,
  width: '100%',
  // backgroundColor: containedButton.main.bgDefault,
  border: `2px solid ${containedButton.main.color}`,
  boxShadow: 'none',
  fontSize: '18px',
  fontWeight: '800',

  [breakpoints.down('md')]: {
    fontSize: '16px',
    fontWeight: '700',
    borderColor: 'transparent'
  },

  '&:hover': {
    boxShadow: 'none',
    color: containedButton[styleVar].color
    // backgroundColor: containedButton.main.bgHover,
    // borderColor: containedButton.main.bgHover
  },
  '&:active': {
    boxShadow: 'none',
    color: containedButton[styleVar].color
    // backgroundColor: containedButton.main.bgActive,
    // borderColor: containedButton.main.bgActive
  },
  '&:disabled': {
    boxShadow: 'none',
    color: containedButton[styleVar].color
    // backgroundColor: containedButton.main.bgDisabled,
    // borderColor: containedButton.main.bgDisabled
  }
}));

export const MainRadioBtn = styled(FormControlLabel)(() => ({
  '.Mui-checked': {
    '.MuiFormControlLabel-label': {
      fontSize: '16px',
      color: '#252A34',
      fontWeight: '700'
    }
  }
}));

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px #137cbd'
      : 'inset 0 0 0 2px #137cbd, inset 0 -2px 0 #137cbd',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
  }
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3'
  }
});
