// @ts-nocheck
import { createSelector, createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';

import { RootState } from '@store';
import { Sort } from '@utils/types';
import { ALL, defaultSortParams, DocumentContentType } from '@helpers';
import { NetworkUser } from '@state/users/types';
import {
  File,
  FileBreadcrumb,
  FilesViewType,
  MIN_GRID_ITEM_SIZE,
  ROOT_DIRECTORY,
  FileAccessStatus,
  FileAccessType
} from '@features/files/types';

import { FileItem } from '@shared/components/file-viewer';
import { Nullable } from '../../globalTypes';

interface PrevLocationData {
  parentDirId: string;
  parentScrollPosition?: number;
}

interface FilesState {
  isMobile: boolean;
  activeActionModalWindow: string | null;
  viewType: FilesViewType;
  selectedIds: EntityId[];
  selectedFiles: any;
  slideShowParams: {
    isOpenModal: boolean;
    isActive: boolean;
    directory: string;
    isFullScreen: boolean;
    delay: number;
    isPreload: boolean;
    isLooped: boolean;
    selectedFile: string;
    selectedFileName: string;
  };
  filesRequestParams: {
    length: number;
    offset: number;
    searchTerm: string;
    accessStatus: FileAccessStatus | null;
    accessType: FileAccessType;
    contentType: DocumentContentType | null;
    totalItems: number | null;
  };
  downloadStatus: {
    isStarted: boolean;
    errorMsg: string | null;
    percent: number;
    isFinish: boolean;
    isCollapsed: boolean;
  };
  breadcrumbs: FileBreadcrumb[];
  gridItemSize: number;
  sort: Sort;
  scrollPosition: number;
  prevLocationData: Nullable<PrevLocationData>;
  activeUser?: Nullable<NetworkUser>;
  selectMode?: string | null;
  isUpload: {
    id: number;
    mode: string | undefined;
  } | null;
  isNewFolderModal?: boolean;
  deleteAllFilesModal: boolean;
}

interface SelectFilesPayload {
  ids: EntityId[];
  selected: boolean;
}

const initialState: FilesState = {
  isMobile: false,
  viewType: 'list',
  activeActionModalWindow: null,
  selectedIds: [],
  selectedFiles: [],
  breadcrumbs: [
    {
      name: 'All Files',
      id: '.',
      isFile: false
    } as FileBreadcrumb
  ],
  slideShowParams: {
    isOpenModal: false,
    isActive: false,
    directory: '',
    isFullScreen: true,
    delay: 5,
    isPreload: false,
    isLooped: false,
    selectedFile: '',
    selectedFileName: ''
  },
  filesRequestParams: {
    length: 20,
    offset: 0,
    searchTerm: '',
    accessStatus: null,
    accessType: ALL,
    contentType: null,
    totalItems: null
  },
  downloadStatus: {
    isStarted: false,
    errorMsg: null,
    percent: 0,
    isFinish: false,
    isCollapsed: true
  },
  gridItemSize: MIN_GRID_ITEM_SIZE,
  sort: defaultSortParams,
  scrollPosition: 0,
  prevLocationData: null,
  activeUserId: null,
  selectMode: null,
  isUpload: null,
  isNewFolderModal: false,
  deleteAllFilesModal: false
} as FilesState;

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    cleanupState: () => ({ ...initialState }),
    setIsMobile: (state: FilesState, { payload }: PayloadAction<boolean>) => {
      state.isMobile = payload;
    },
    setActiveActionModalWindow: (state: FilesState, { payload }: PayloadAction<string | null>) => {
      state.activeActionModalWindow = payload;
    },
    changeSlideShow: (state: FilesState, { payload }: PayloadAction<any>) => {
      state.slideShowParams = { ...state.slideShowParams, ...payload };
    },
    changeSort: (state: FilesState, { payload }: PayloadAction<Sort>) => {
      state.sort = payload;
    },
    resetSort: (state) => {
      state.sort = defaultSortParams;
    },
    changeViewType: (state: FilesState, action: PayloadAction<FilesViewType>) => {
      state.viewType = action.payload;
      state.selectedIds = [];
    },
    changeGridSize: (state: FilesState, action: PayloadAction<number>) => {
      state.gridItemSize = action.payload;
    },
    changeSelected: (state: FilesState, action: PayloadAction<SelectFilesPayload>) => {
      const { selected, ids } = action.payload;
      return selected
        ? update(state, { selectedIds: { $push: ids } })
        : {
            ...state,
            selectedIds: state.selectedIds.filter((selectedId) => !ids.includes(selectedId))
          };
    },
    setSelectedFiles: (state: FilesState, action: PayloadAction<File | FileItem>) => {
      const file = action.payload;

      return state.selectedFiles.find(
        (selectedFile: File) => selectedFile.file_id === file?.file_id
      )
        ? {
            ...state,
            selectedFiles: state.selectedFiles.filter(
              (selectedFile: File) => selectedFile.file_id !== file?.file_id
            )
          }
        : {
            ...state,
            selectedFiles: [...state.selectedFiles, file]
          };
    },
    clearSelectedFiles: (state: FilesState) => {
      state.selectedFiles = [];
    },
    setPreviewSelected: (state: FilesState, action: PayloadAction<EntityId>) => {
      state.selectedIds = [action.payload];
    },
    clearSelectedFilesIds: (state: FilesState) => {
      state.selectedIds = [];
    },
    changeAccessTypeFilter: (state: FilesState, action: PayloadAction<FileAccessType>) => {
      state.filesRequestParams = { ...state.filesRequestParams, accessType: action.payload };
    },
    changeDownloadStatus: (state: FilesState, action: PayloadAction<anys>) => {
      state.downloadStatus = {
        ...state.downloadStatus,
        ...action.payload
      };
    },
    resetDownloadStatus: (state: FilesState) => {
      state.downloadStatus = initialState.downloadStatus;
    },
    changeSearchTerm: (state: FilesState, action: PayloadAction<string>) => {
      state.filesRequestParams = {
        ...state.filesRequestParams,
        searchTerm: action.payload,
        offset: 0,
        accessType: ALL,
        totalItems: null
      };
    },
    setFilesRequestParams: (state: FilesState, action: PayloadAction<any>) => {
      state.filesRequestParams = { ...state.filesRequestParams, ...action.payload };
    },
    sliceBreadcrumbsById: (state: FilesState, action: PayloadAction<string>) => {
      state.filesRequestParams = {
        ...state.filesRequestParams,
        length: 20,
        offset: 1,
        searchTerm: '',
        accessStatus: null,
        accessType: ALL,
        totalItems: null
      };

      state.breadcrumbs = state.breadcrumbs.slice(
        0,
        state.breadcrumbs.map(({ id }) => id).indexOf(action.payload) + 1
      );
    },
    setBreadcrumbs: (
      state: FilesState,
      action: PayloadAction<{ filesRequestParams: any; breadcrumbs: any[] }>
    ) => {
      state.filesRequestParams = {
        ...state.filesRequestParams,
        ...action.payload.filesRequestParams
      };
      state.breadcrumbs = action.payload.breadcrumbs;
    },
    addToBreadcrumbs: (state, action: PayloadAction<FileBreadcrumb>) => {
      const newState = update(state, { breadcrumbs: { $push: [action.payload] } });

      newState.filesRequestParams = { ...state.filesRequestParams, ...action.payload };

      return newState;
    },
    addMultipleToBreadcrumbs: (state, action: PayloadAction<FileBreadcrumb[]>) => {
      const newState = update(state, { breadcrumbs: { $push: action.payload } });

      // Если нужно обновить `filesRequestParams`, на основе всех добавляемых breadcrumb'ов:
      action.payload.forEach((breadcrumb) => {
        newState.filesRequestParams = { ...newState.filesRequestParams, ...breadcrumb };
      });

      return newState;
    },
    removeFromBreadcrumbs: (state: FilesState, action: PayloadAction<string>) => {
      state.filesRequestParams = initialState.filesRequestParams;
      const deleteItemIndex = state.breadcrumbs
        .map((breadcrumb) => breadcrumb.id)
        .indexOf(action.payload);
      if (deleteItemIndex !== -1) {
        state.breadcrumbs = state.breadcrumbs.slice(0, deleteItemIndex);
      }
    },
    removeFinishBreadcrumb: (state: FilesState) => {
      state.filesRequestParams = initialState.filesRequestParams;
      state.breadcrumbs = state.breadcrumbs.slice(0, -1);
    },
    setActiveUser: (state: FilesState, { payload }: PayloadAction<NetworkUser>) => {
      state.activeUser = payload;
    },
    setPrevLocationData: (state: FilesState, { payload }: PayloadAction<PrevLocationData>) => {
      state.prevLocationData = payload;
    },
    resetPrevLocationData: (state: FilesState) => {
      state.prevLocationData = null;
    },
    setScrollPosition: (state: FilesState, { payload }: PayloadAction<number>) => {
      state.scrollPosition = payload;
    },
    setSelectMode: (state: FilesState, { payload }: PayloadAction<string>) => {
      state.selectMode = payload;
    },
    setIsUpload: (
      state: FilesState,
      { payload }: PayloadAction<{ id: number | null; mode: string | undefined } | null>
    ) => {
      state.isUpload = payload;
    },
    setIsNewFolderModal: (state: FilesState, { payload }: PayloadAction<boolean>) => {
      state.isNewFolderModal = payload;
    },
    setDeleteAllFilesModal: (state: FilesState, { payload }: PayloadAction<boolean>) => {
      state.deleteAllFilesModal = payload;
    }
  }
});

export default filesSlice.reducer;

export const {
  setIsMobile,
  setActiveActionModalWindow,
  changeSlideShow,
  changeSearchTerm: changeFilesSearchTerm,
  setFilesRequestParams,
  changeViewType: changeFilesViewType,
  changeSelected: changeSelectedFiles,
  setPreviewSelected: setPreviewSelectedFileId,
  clearSelectedFilesIds,
  changeSort: changeSortingState,
  changeGridSize: changeFilesGridSize,
  sliceBreadcrumbsById: sliceFileBreadcrumbsById,
  addToBreadcrumbs: addToFileBreadcrumbs,
  addMultipleToBreadcrumbs,
  removeFromBreadcrumbs: removeFromFileBreadcrumbs,
  removeFinishBreadcrumb,
  resetSort,
  setActiveUser,
  cleanupState,
  setPrevLocationData,
  resetPrevLocationData,
  setScrollPosition,
  setSelectMode,
  setIsUpload,
  setIsNewFolderModal,
  setSelectedFiles,
  clearSelectedFiles,
  setDeleteAllFilesModal,
  changeAccessTypeFilter,
  setBreadcrumbs,
  changeDownloadStatus,
  resetDownloadStatus
} = filesSlice.actions;

const selectFilesState = (state: RootState) => state.files;

export const selectSlideShowState = (state: RootState) => state.files.slideShowParams;
export const selectIsMobile = (state: RootState) => state.files.isMobile;

export const selectedFilesState = (state: RootState): File[] => state.files.selectedFiles;
export const sortState = createSelector(selectFilesState, (state) => state.sort);
export const selectFilesRequestParams = createSelector(
  selectFilesState,
  (state) => state.filesRequestParams
);

export const selectFilesViewType = createSelector(selectFilesState, (state) => state.viewType);
export const selectSelectMode = createSelector(selectFilesState, (state) => state.selectMode);
export const selectIsUpload = createSelector(selectFilesState, (state) => state.isUpload);
export const selectDownloadStatus = createSelector(
  selectFilesState,
  (state) => state.downloadStatus
);
export const selectIsNewFolderModal = createSelector(
  selectFilesState,
  (state) => state.isNewFolderModal
);

export const selectDeleteAllFilesModal = createSelector(
  selectFilesState,
  (state) => state.deleteAllFilesModal
);

export const selectSelectedFilesIds = createSelector(selectedFilesState, (state) =>
  state.map((elem) => elem.file_id)
);

export const selectFilesGridItemSize = createSelector(
  selectFilesState,
  (state) => state.gridItemSize
);
export const selectFilesBreadcrumbs = createSelector(
  selectFilesState,
  (state) => state.breadcrumbs
);

export const selectActiveFileId = createSelector(
  selectFilesBreadcrumbs,
  (breadcrumbs) => breadcrumbs.filter((breadcrumb) => breadcrumb.isFile).pop()?.id ?? ''
);
export const selectActiveDirectory = createSelector(
  selectFilesBreadcrumbs,
  (breadcrumbs) =>
    breadcrumbs.filter((breadcrumb) => !breadcrumb.isFile && !breadcrumb.isUser).pop() ??
    ({ id: ROOT_DIRECTORY, isFile: false } as FileBreadcrumb)
);

export const selectPrevLocationData = createSelector(
  selectFilesState,
  (state) => state.prevLocationData
);

export const selectScrollOffsetItem = createSelector(
  selectFilesState,
  (state) => state.prevLocationData?.parentScrollPosition
);

export const selectScrollPosition = createSelector(
  selectFilesState,
  (state) => state.scrollPosition
);

export const selectActiveActionModalWindow = createSelector(
  selectFilesState,
  (state) => state.activeActionModalWindow
);
