import { useQuery } from '@apollo/client';
import { CURRENT_USER } from '@graphql/queries';
import { useContext } from 'react';
import { AuthContext } from '@contexts';
import { useAppDispatch } from '@hooks/useStore';
import { setUserData } from '@state/user/userSlice';

export const useGetCurrentUserData = () => {
  const { user } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { data, loading, error, refetch } = useQuery(CURRENT_USER, {
    variables: {
      userId: user?.username
    },
    fetchPolicy: 'cache-first'
  });
  dispatch(setUserData(data?.getUser));

  return {
    data: data?.getUser || {},
    isLoading: loading,
    isError: Boolean(error),
    isSuccess: Boolean(data?.getUser),
    refetch
  };
};
