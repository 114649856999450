import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { AuthUser } from '@contexts';
import { Auth } from 'aws-amplify';
import { getJwtTokenFromCurrentSession } from '@utils/getJwtTokeFromCurrentSession';
import { LocalStorageService } from '@services';
import { LocalStorageKeys } from '@helpers';
import { analytics } from '@analytics';
import { useGetCurrentUserData } from '@hooks/useGetCurrentUserData';
import { AnyType } from '../globalTypes';

const useAuth = () => {
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState<AuthUser>(null);

  const mounted = useRef(false);

  const checkAuth = async () => {
    try {
      setLoading(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      const AccessToken = await getJwtTokenFromCurrentSession();
      LocalStorageService.set(LocalStorageKeys.TOKEN, AccessToken);
      setUser(currentUser);
    } catch {
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!mounted.current) {
      checkAuth().then();
    }
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, [setUser]);

  const signIn = useCallback(
    async (email: string, password: string) => {
      const loggedUser = await Auth.signIn(email, password);
      setUser(loggedUser);

      return loggedUser;
    },
    [setUser]
  );

  const signOut = useCallback(async () => {
    await Auth.signOut();
    analytics.authLogOut({ user_email: user?.attributes?.email || '' });
    LocalStorageService.delete(LocalStorageKeys.ATTEMPTED_UPDATE_PLAN);
    setUser(null);
  }, [setUser]);

  const refreshSession = useCallback(async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    return new Promise<{
      idToken: AnyType;
      refreshToken: AnyType;
      accessToken: AnyType;
    }>((resolve, reject) => {
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (errors: AnyType, session: AnyType) => {
          if (errors) {
            reject(errors);
          }

          const { idToken, refreshToken, accessToken } = session;

          resolve({
            idToken,
            refreshToken,
            accessToken
          });
        }
      );
    });
  }, []);

  const idUserToken = useMemo(() => user?.getSignInUserSession()?.getIdToken()?.payload, [user]);

  return { user, idUserToken, refreshSession, signIn, signOut, isLoading };
};

export default useAuth;
