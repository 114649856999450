import { AcceptableFileExtentions } from '@features/files/types';
import { ListColumnDefKeys } from '@shared/components/list-view-v2/types';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export const defaultSortParams = {
  key: 'order_priority',
  direction: SortDirection.ASC
};

export enum CustomerSubscriptionPlan {
  GB1 = `1 Gb`,
  GB50 = '50 Gb',
  GB250 = '250 Gb',
  GB1000 = '1 Tb',
  GB5000 = '5 Tb'
}
export enum PageSizeOptions {
  TEN = 10,
  TWENTY = 20,
  THIRTY = 30
}

export enum ViewPageSize {
  LIST = 10,
  GRID = 30
}

export const DEFAULT_PAGE_NUMBER = 1;
export const TIMEOUT_SCROLL_BEHAVIOUR = 50;
export const PREVIEW_PAGE_SIZE = 20;
export const OVERSCAN_VIRTUALIZE_THUMBNAILS = 10;
export const THUMBNAILS_GAP = 16;

export enum DocumentContentType {
  DIRECTORY = 'directory',
  APPLICATION_PDF = 'application/pdf',
  AUDIO_MPEG = 'audio/mpeg',
  AUDIO_AAC = 'audio/aac',
  AUDIO_OGG = 'audio/ogg',
  AUDIO_WAV = 'audio/wav',
  VIDEO_AVI = 'video/avi',
  VIDEO_WMV = 'video/x-ms-wmv',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_MPEG = 'video/mpeg',
  VIDEO_MOV = 'video/quicktime',
  VIDEO_QUICKTIME = 'video/quicktime',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_JPG = 'image/jpg',
  IMAGE_PNG = 'image/png',
  IMAGE_TIFF = 'image/tiff',
  IMAGE_TIF = 'image/tif',
  TEXT_PLAIN = 'text/plain',
  TEXT_HTML = 'text/html',
  APPLICATION_MSWORD = 'application/msword',
  APPLICATION_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  APPLICATION_POWERPOINT = 'application/vnd.ms-powerpoint',
  APPLICATION_POWERPOINTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  APPLICATION_EXCEL = 'application/vnd.ms-excel',
  APPLICATION_EXCELX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const PAGES = {
  Root: '/',
  SignUp: '/signup',
  CangePassword: '/change-password',
  Login: '/login',
  Home: '/home',
  Admin: `/admin`,
  Staff: '/staff',
  AllMemories: '/all',
  MemoriesSharing: '/shared',
  Received: '/received',
  RecoveringPassword: {
    Initial: '/recovering-password',
    Code: '/recovering-password/code'
  },
  My: 'my'
};

export enum UserRole {
  ADMIN = 'admin',
  STAFF = 'staff',
  CUSTOMER = 'customer'
}

export enum ActionsFile {
  DOWNLOAD = 'DOWNLOAD',
  COPY = 'COPY',
  MOVE = 'MOVE',
  SHARE = 'SHARE',
  DELETE = 'DELETE'
}

export enum LocalStorageKeys {
  TOKEN = 'TOKEN',
  USER = 'USER',
  USER_ID = 'USER_ID',
  THEME = 'THEME',
  ATTEMPTED_UPDATE_PLAN = 'ATTEMPTED_UPDATE_PLAN'
}

export enum SubscriptionSource {
  System = 'system',
  Stripe = 'stripe',
  Staff = 'staff'
}

export enum AttemptedUpgradeStatus {
  NONE = 0,
  Attempted = 1
}

export enum FileSize {
  GB = 'Gb',
  MB = 'Mb',
  KB = 'Kb',
  B = 'B'
}

export const AllActionsFile = [
  ActionsFile.DOWNLOAD,
  ActionsFile.COPY,
  ActionsFile.MOVE,
  ActionsFile.SHARE,
  ActionsFile.DELETE
];

export const ALL = 'all';
export const OWNED = 'owner';
export const PRIVATE = 'private';
export const RECEIVED = 'received';
export const SHARED = 'shared';
export const INTRASH = 'in_trash';
export const NOTINTRASH = '-in_trash';
export const SHARED_BY_NETWORK = 'sharedByNetwork';
export const PREVIEW = 'preview';

export enum BillingPeriod {
  MONTHLY = '1M',
  YEARLY = '12M'
}

export enum Subscriptions {
  GB_1 = '1 Gb',
  GB_50 = '50 Gb',
  GB_250 = '250 Gb',
  Tb_1 = '1 Tb',
  Tb_5 = '5 Tb'
}

export enum Statuses {
  Active = 'active',
  Suspend = 'suspend',
  Backup = 'backup',
  Deleted = 'deleted',
  Invited = 'invited',
  Pending = 'pending'
}

export enum DateFormats {
  YYYY_MM_DD = 'YYYY-MM-DD',
  MM_DD_YYYY_SLASH_SEPARATE = 'MM/DD/YYYY'
}

export const SuscriptionPlans = [
  {
    id: 1,
    title: CustomerSubscriptionPlan.GB1,
    value: CustomerSubscriptionPlan.GB1
  },
  {
    id: 2,
    title: CustomerSubscriptionPlan.GB50,
    value: CustomerSubscriptionPlan.GB50
  },
  {
    id: 3,
    title: CustomerSubscriptionPlan.GB250,
    value: CustomerSubscriptionPlan.GB250
  },

  {
    id: 4,
    title: CustomerSubscriptionPlan.GB1000,
    value: CustomerSubscriptionPlan.GB1000
  },
  {
    id: 5,
    title: CustomerSubscriptionPlan.GB5000,
    value: CustomerSubscriptionPlan.GB5000
  }
];

export const DEFAULT_AVAILABILITY = 'true';

export const AUTO_CLOSE_GLOBAL_TOAST_DURATION = 5000;

export const IncludingPendingStatusErrorMessages = [
  'Cannot reset password for the user as there is no registered/verified email or phone_number',
  'User is not confirmed.'
];

export const IncludingInvitedStatusErrorMessages = [
  'Temporary password',
  'User password cannot be reset in the current state.'
];

export const SomethingWentWrongGlobalMessage = 'Something went wrong';

export const acceptableExtensions: AcceptableFileExtentions[] = [
  '.PNG',
  '.JFIF',
  '.AVI',
  '.WMV',
  '.JPG',
  '.JPEG',
  '.MP4',
  '.MP3',
  '.MOV',
  '.FLAC',
  '.PDF',
  '.DOCX',
  '.DOC',
  '.PPT',
  '.PPTX',
  '.TIF',
  '.TIFF',
  '.TXT',
  '.XLS',
  '.XLSX',
  '.HTML'
];
