// import { gql } from 'src/__generated__/';  // TODO find solution for @client directive
import { gql } from '@apollo/client';

export const LIST_FILES = gql(`
query ListFiles($filter: UserFileFilter, $order: UserFileOrder, $offset: Int, $length: Int) {
    queryUserFile(filter: $filter, order: $order, offset: $offset, first: $length) {
    access_status
    access_type
    file {
      file_id
      file_type
      content_type
      name
      description
      file_size  
      duration
      created
      last_updated
      transcode_status{
        video
      }
      transcode_complete
      parent_dir {
      file_id
      name
      }
      comments {
        comment_id
      }
      stream_path
      thumbnails
      thumbnail_url @client
      access_type @client
      access_status @client
      url @client
    }
  }
  aggregateUserFile(filter:$filter) {
    count
  }
}
`);

export const TRASH_LIST_FILES = gql(`
query TrashListFiles($filter: UserFileFilter, $order: UserFileOrder, $offset: Int, $length: Int) {
    queryUserFile(filter: $filter, order: $order, offset: $offset, first: $length) {
    access_status
    access_type
    file {
      name
      created
      file_size
      content_type
      description
      last_updated
      file_id
      thumbnails
      parent_dir {
      file_id
      name
      }
      thumbnail_url @client
      access_type @client
      access_status @client
      url @client
    }
  }
    aggregateUserFile(filter:$filter) {
    count
  }
}
`);

export const DIRECTORY_LIST_FILES = gql(`
query DirectoryListFiles($filter: UserFileFilter, $order: UserFileOrder, $offset: Int, $length: Int) {
  queryUserFile(filter: $filter, order: $order, offset: $offset, first: $length) {
    file {
      name
      parent_dir_id
      file_id
    }
  }
 aggregateUserFile(filter:$filter) {
    count
  }
}
`);

export const FILE_DETAILS = gql(`
  query GetFileDetails($filter: UserFileFilter) {
    queryUserFile(filter: $filter, offset: 0, first: 1 ) {
    file {
    description
      comments {
        emoticons {
          commentors {
            given_name
            user_id
            family_name
          }
          emoticon
          id
        }
        commentor {
          given_name
          family_name
          user_id
          profile_image
        }
        comment_text
        comment_id
        comment_date
      }
      users {
        access_status
        user {
          given_name
          family_name
          email
          state
          profile_image
        }
        user_id
      }
      file_id
    }
  }
  }
`);

export const CURRENT_USER = gql(`
 query GetUser($userId: String!) {
  getUser(user_id: $userId) {
    user_id
    subscription_source
    subscription
    state
    is_deleted
    given_name
    family_name
    expiration_date
    email_verified
    email
    account_type
    profile_image
  }
}
`);
