import { createTheme } from '@mui/material';

export const breakpoints = {
  values: {
    xs: 0, // Phone
    sm: 700, // Tablet
    md: 1440, // Tablet/NewBook
    lg: 1600, // laptop
    xl: 1920 //
  }
};

declare module '@mui/material/styles' {
  interface PaletteOptions {
    containedButton?: any; // Дополнительный цвет 1
  }
}

export const device = {
  xs: `(min-width: ${breakpoints.values.xs})`, // Phone
  sm: `(min-width: ${breakpoints.values.sm})`, // Tablet
  md: `(min-width: ${breakpoints.values.md})`, // Tablet/NewBook
  lg: `(min-width: ${breakpoints.values.lg})`, // laptop
  xl: `(min-width: ${breakpoints.values.xl})` //
};

const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff'
    },
    text: {
      primary: '#252A34',
      disabled: '#4F4F4F'
    },
    primary: {
      // @ts-ignore
      color_1: '#6ECDC5',
      color_2: '#252A34',
      main: '#6ECDC5',
      light: '#9bc6e3',
      dark: '#0345cc'
    },
    secondary: {
      main: '#your_secondary_color'
    },
    warning: {
      main: '#eb5757',
      light: '#ffeded',
      dark: '#e20000'
    },
    containedButton: {
      primary: {
        color: '#252A34',
        bgDefault: '#6ECDC5',
        bgActive: '#2EA7AF',
        bgHover: '#83E2DA',
        bgDisabled: '#E0E0E0'
      },
      secondary: {
        color: '#FFFFFF',
        bgDefault: '#252A34',
        bgActive: '#14171D',
        bgHover: '#343B48',
        bgDisabled: '#E0E0E0'
      },
      light: {
        color: '#252A34',
        bgDefault: '#FFFFFF',
        bgActive: '#BDBDBD',
        bgHover: '#F3F3F3',
        bgDisabled: '#E0E0E0'
      },
      main: {
        color: '#252A34',
        bgDefault: '#6ECDC5',
        bgActive: '#2EA7AF',
        bgHover: '#83E2DA',
        bgDisabled: '#E0E0E0'
      }
    },
    error: {
      main: '#F06B6B'
    }
  },
  breakpoints,
  typography: {
    fontFamily: ['Red Hat Display'].join(','),
    button: {
      textTransform: 'none'
    },
    h1: {
      fontSize: '36px',
      [`@media screen and (max-width: ${breakpoints.values.xl}px)`]: {
        fontSize: '36px'
      },
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '36px'
      },
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: '34px'
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '32px'
      },
      [`@media screen and (max-width: ${breakpoints.values.xs}px)`]: {
        fontSize: '32px'
      }
    },
    h2: {
      fontSize: '18px',
      [`@media screen and (max-width: ${breakpoints.values.xl}px)`]: {
        fontSize: '18px'
      },
      [`@media screen and (max-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '18px'
      },
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: '18px'
      },
      [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '18px'
      },
      [`@media screen and (max-width: ${breakpoints.values.xs}px)`]: {
        fontSize: '18px'
      }
    }
  }
});

theme.components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        overflow: 'hidden',
        fontFamily: 'Red Hat Display'
      }
    }
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
        textDecorationColor: theme.palette.text.primary
      }
    }
  }
};

export default theme;
