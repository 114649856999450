import React, { useCallback, useState, ChangeEvent, useEffect } from 'react';
import { debounce } from '@mui/material';
import equal from 'react-fast-compare';
import { SearchContainer, SearchInput, SearchAdornment } from './styles';

interface SearchFieldProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  customStyles?: {
    containerStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    adornmentStyle?: React.CSSProperties;
  };
}

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { placeholder, value, onChange, customStyles = {}, disabled } = props;
  const { containerStyle, inputStyle, iconStyle, adornmentStyle } = customStyles;
  const [searchTerm, setSearchTerm] = useState(value);

  useEffect(() => {
    if (searchTerm !== value) {
      setSearchTerm(value);
    }
  }, [value]);

  const debouncedSearchHandler = useCallback(debounce(onChange, 500), []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    debouncedSearchHandler(newValue);
  };

  const clearInput = () => {
    setSearchTerm('');
    onChange('');
  };

  return (
    <SearchContainer style={containerStyle}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        style={{ height: '24px', marginRight: '12px', ...adornmentStyle }}
        htmlFor="topbar-search-input"
      >
        <SearchAdornment alt="focus on search icon" src="/img/search_icon.svg" style={iconStyle} />
      </label>
      <SearchInput
        alt="search input"
        id="topbar-search-input"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        style={inputStyle}
        disabled={disabled}
      />
      {value && (
        <SearchAdornment
          alt="clear search icon"
          onClick={clearInput}
          src="/img/close_circle_icon.svg"
          style={iconStyle}
        />
      )}
    </SearchContainer>
  );
};

export default React.memo(SearchField, equal);
