import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  selectUsersSort,
  isUserSelected,
  selectedUserIds,
  selectUsers,
  changeUsersSort,
  selectUserById,
  selectUsersPagination,
  changeUsersPage,
  selectUsersPageIds,
  selectUserRequestStatus
} from '@state/users/userSlice';
import { ListView } from '@shared/components/list-view';
import { fetchUsers } from '@state/users/thunks';
import { UserRole } from '@helpers';
import { useAppSelector } from '@hooks/useStore';
import { PageLoader } from '@shared/components';
import { RequestStatus } from '@utils/types';
import {
  ListColumnDef,
  ListPageChangeHandler,
  ListRowSelectHandler,
  ListSortChangeHandler
} from '../../shared/components/list-view/types';

interface UsersViewProps {
  columns: ListColumnDef[];
  selectedUserRole: UserRole;
}

export const UsersView = ({ columns, selectedUserRole }: UsersViewProps) => {
  const dispatch = useDispatch();
  const status = useAppSelector(selectUserRequestStatus);

  useEffect(() => {
    if (status === RequestStatus.IDLE && selectedUserRole) {
      dispatch(fetchUsers(selectedUserRole));
    }
  }, [dispatch, status, selectedUserRole]);

  const handleUserSelect: ListRowSelectHandler = (ids, selected, index) => {
    dispatch(selectUsers({ ids, selected, index }));
  };

  const handleUsersSortChange: ListSortChangeHandler = (sort) => {
    dispatch(changeUsersSort(sort));
  };

  const handleUsersPageChange: ListPageChangeHandler = (newPage) => {
    dispatch(changeUsersPage(newPage));
  };

  return (
    <>
      <ListView
        columns={columns}
        onRowSelect={handleUserSelect}
        onSortChange={handleUsersSortChange}
        onPageChange={handleUsersPageChange}
        onRowClick={() => true}
        entitySelectors={{
          ids: selectUsersPageIds,
          byId: selectUserById,
          isSelected: isUserSelected,
          selectedIds: selectedUserIds,
          sort: selectUsersSort,
          pagination: selectUsersPagination
        }}
      />
      <PageLoader open={status === RequestStatus.LOADING} />
    </>
  );
};
