import { DateTime } from 'luxon';
import i18n from 'i18next';
import { Sort } from '@utils/types';
import { ListEntity } from '@shared/components/list-view-v2/types';
import { EntityId } from '@reduxjs/toolkit';
import { DocumentContentType, SortDirection } from '@helpers';
import { ENVIRONMENT, PLATFORM } from '@aws';
import { FileItem } from '@shared/components/file-viewer';
import { supportedImages } from '@utils/supported-media-types';
import { CustomSelectOption } from '@shared/components/inputs/Inputs';
import {
  File1,
  FetchFilesResponse,
  EDITOR,
  OWNER,
  FileAccessStatus,
  CONTRIBUTOR,
  GpraphQLFile,
  FileContent,
  AccessStatus
} from './types';

export const isDirectory = (type: string) => {
  return type === DocumentContentType.DIRECTORY;
};

const getThumbnail = (thumbnails: any, content_type: DocumentContentType, file_id: string) => {
  if (thumbnails?.[0]) {
    return `${PLATFORM[ENVIRONMENT].file_content_url}/${thumbnails[0]}`;
  }
  if (supportedImages.includes(content_type)) {
    return `${PLATFORM[ENVIRONMENT].file_thumbnail_url}/${file_id}_150`;
  }
  return '';
};

const getFormattedFileDate = (): string => {
  return DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss', {
    locale: i18n.language
  });
};

export const processFile = (incomingFile: GpraphQLFile): File1 => {
  return {
    ...incomingFile,
    file: {
      ...incomingFile.file,
      url: `${PLATFORM[ENVIRONMENT].file_content_url}/${incomingFile.file?.file_id}`,
      thumbnail_url: getThumbnail(
        incomingFile.file?.thumbnails,
        incomingFile.file?.content_type,
        incomingFile.file?.file_id
      ),
      access_type: incomingFile.access_type,
      access_status: incomingFile.access_status
    }
  };
};

// TODO refactor sorting
export const filesCompareFn = ({ key, direction }: Sort) => (
  a: ListEntity | FileItem,
  b: ListEntity | FileItem
) => {
  const fileA = a as File;
  const fileB = b as File;

  const aValue = fileA[key]?.toString() || '';
  const bValue = fileB[key]?.toString() || '';

  const contentTypeOrder = Object.values(DocumentContentType);

  let result: number;

  if (key === 'content_type') {
    result =
      contentTypeOrder.indexOf(fileA.content_type) - contentTypeOrder.indexOf(fileB.content_type);
  } else if (!aValue && !bValue) {
    return 0;
  } else if (aValue && !bValue) {
    return -1;
  } else if (!aValue && bValue) {
    return 1;
  } else {
    result = aValue.localeCompare(bValue);
  }

  return direction === SortDirection.ASC ? result : result * -1;
};

export const getSearchedFiles = (files: File[], searchTerm: string): File[] => {
  if (!searchTerm) return files;

  return files.filter((file) => {
    const fileSearchString = `${file.name}${file.description}`.toLocaleLowerCase().trim();
    return fileSearchString.includes(searchTerm.toLocaleLowerCase().trim());
  });
};

export const updateWithAdditionalInfo = (file: File): File => {
  return {
    ...file,
    url: `${PLATFORM[ENVIRONMENT].file_content_url}/${file.file_id}`,
    thumbnail_url: getThumbnail(file),
    last_updated: file.last_updated ? file.last_updated : getFormattedFileDate()
  };
};
export const updateSharedFilesWithAdditionalInfo = (file: File): File => {
  return {
    ...file,
    url: `${PLATFORM[ENVIRONMENT].file_content_url}/${file.file_id}`,
    thumbnail_url: getThumbnail(file),
    last_updated: file.last_updated ? file.last_updated : getFormattedFileDate(),
    isShared: true
  };
};

export const isFileEditable = (
  listEntity: FileContent | { result: string; target_email: string } | undefined | File | FileItem
) => {
  if (!listEntity) return false;
  if (!('access_status' in listEntity)) return false;
  return [OWNER, CONTRIBUTOR, EDITOR].includes(listEntity.access_status as FileAccessStatus);
};

export const isFileEditableByAccessStatus = (listEntity: AccessStatus) => {
  if (!listEntity) return false;
  if (!('type' in listEntity)) return false;
  return [OWNER, CONTRIBUTOR, EDITOR].includes(listEntity.type as FileAccessStatus);
};

export const getFilesByURL = (url: string) => {
  return fetch(url, {
    method: 'GET'
  })
    .then((res) => res.blob())
    .then((blob) => blob.text())
    .then((text) => JSON.parse(text) as File[]);
};

export const selectDirectoriesFromApiResult = (
  result: { data?: File[] },
  excludeIds?: EntityId[]
) => ({
  ...result,
  data:
    result.data?.filter(
      ({ file_id, content_type }) => isDirectory(content_type) && !excludeIds?.includes(file_id)
    ) ?? []
});

export const getDirectoriesOptions = (directories: File[]): CustomSelectOption[] => {
  return directories.map(
    (dir) =>
      ({
        value: dir.file_id,
        label: dir.name
      } as CustomSelectOption)
  );
};

export const getDirectoriesWithStatusEditor = (directories: File[]) =>
  directories.filter(({ access_status }) => access_status === EDITOR);
