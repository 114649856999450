// @ts-nocheck
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store';

interface UserState {
  user_id: string | undefined;
  subscription_source: string | undefined;
  subscription: string | undefined;
  state: string | undefined;
  is_deleted: boolean | undefined;
  given_name: string | undefined;
  family_name: string | undefined;
  expiration_date: any | undefined;
  email_verified: boolean | undefined;
  email: string | undefined;
  account_type: string | undefined;
  profile_image: string | undefined;
}

const initialState: UserState = {
  user_id: undefined,
  subscription_source: undefined,
  subscription: undefined,
  state: undefined,
  is_deleted: undefined,
  given_name: undefined,
  family_name: undefined,
  expiration_date: undefined,
  email_verified: undefined,
  email: undefined,
  account_type: undefined
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    cleanupState: () => ({ ...initialState }),
    setUserData: (state: FilesState, { payload }: PayloadAction<UserState>) => {
      return payload;
    },
    updateUserData: (state: FilesState, { payload }: PayloadAction<UserState>) => {
      return {
        ...state,
        ...payload
      };
    }
  }
});

export default userSlice.reducer;

export const { setUserData, updateUserData } = userSlice.actions;

export const selectUserState = (state: RootState) => state.user;
