import React, { useContext } from 'react';
import { Avatar } from '@mui/material';
import { AuthContext } from '@contexts';
import { useAppSelector } from '@hooks/useStore';
import { selectUserState } from '@state/user/userSlice';
import { stringAvatar } from './utils';

export const UserAvatar = ({ sxStyle }: any) => {
  const { user } = useContext(AuthContext);
  const userState = useAppSelector(selectUserState);
  const fullName = `${user?.attributes?.given_name || ''} ${user?.attributes?.family_name || ''}`;
  return <Avatar {...stringAvatar(fullName, userState.profile_image, sxStyle)} />;
};

export const CustomAvatar = ({
  fullName,
  profileImage
}: {
  fullName: string;
  profileImage?: string;
}) => {
  return <Avatar {...stringAvatar(fullName, profileImage)} />;
};
